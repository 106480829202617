import React from 'react';
import clinicImage from '../assets/clinic-image.jpg'; // Add your own image

const Home = () => (
  <div className="container">
    <h2>Welcome to Sadguru Homeopathy Clinic</h2>
    <img src={clinicImage} alt="Clinic" style={{ width: '100%', height: 'auto', borderRadius: '8px' }} />
    <p>Dealing with gynecological problems and child care. Lifestyle disorders like PCOS, PCOD, obesity, hypertension, etc. are treated with medicines as well as proper diet and regime. Pursuing classical homeopathy, sticking to the ethics, and giving miraculous results. Also dealing in psychological counseling. Separate counseling sessions for each patient.</p>
  </div>
);

export default Home;
