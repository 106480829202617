import React from 'react';
import doctorImage from '../assets/doctor-image.jpg'; // Add your own image

const AboutUs = () => (
  <div className="container">
    <h2>About Us</h2>
    <img src={doctorImage} alt="Dr. Geeta Dubey" style={{ width: '200px', height: 'auto', borderRadius: '8px' }} />
    <p>Dr. Geeta Dubey, Batch 2012 from Homeopathy University, specializes in treating gynecological problems, child care, and lifestyle disorders through classical homeopathy, adhering strictly to ethical practices. The clinic also provides psychological counseling with separate sessions for each patient.</p>
  </div>
);

export default AboutUs;
