import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Navbar from './components/Navbar';
import Header from './components/Header';
import Footer from './components/Footer';
import Home from './components/Home';
import AboutUs from './components/AboutUs';
import Blog from './components/Blog';
import ContactUs from './components/ContactUs';
import Location from './components/Location';
import './App.css';

const App = () => (
  <div className="App">
    <Header />
    <Navbar />
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/about" element={<AboutUs />} />
      <Route path="/blog" element={<Blog />} />
      <Route path="/contact" element={<ContactUs />} />
      <Route path="/location" element={<Location />} />
    </Routes>
    <Footer />
  </div>
);

export default App;
