import React from 'react';

const Blog = () => (
  <div className="container">
    <h2>Blog</h2>
    <p>Welcome to our blog section. Stay tuned for updates!</p>
  </div>
);

export default Blog;
