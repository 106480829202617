import React from 'react';

const Location = () => (
  <div className="container">
    <h2>Location</h2>
    <p>Visit us at:</p>
    <p>Sadguru Homeopathy Clinic</p>
    <p>123 Main Street, City, Country</p>
  </div>
);

export default Location;
