import React from 'react';
import { NavLink } from 'react-router-dom';

const Navbar = () => (
  <nav>
    <ul>
      <li><NavLink to="/" exact activeClassName="active">Home</NavLink></li>
      <li><NavLink to="/about" activeClassName="active">About Us</NavLink></li>
      <li><NavLink to="/blog" activeClassName="active">Blog</NavLink></li>
      <li><NavLink to="/contact" activeClassName="active">Contact Us</NavLink></li>
      <li><NavLink to="/location" activeClassName="active">Location</NavLink></li>
    </ul>
  </nav>
);

export default Navbar;
