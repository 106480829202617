import React from 'react';
import headerImage from '../assets/header-image.jpg'; // Make sure the image exists

const Header = () => (
  <header style={{ backgroundImage: `url(${headerImage})`, backgroundSize: 'cover' }}>
    <div style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)', padding: '20px' }}>
      <h1>Sadguru Homeopathy Clinic</h1>
      <h2>Dr. Geeta Dubey</h2>
      <p>Batch 2012, Homeopathy University</p>
    </div>
  </header>
);

export default Header;
